import {
	Component,
	AfterViewInit,
	Input,
	EventEmitter,
	ViewChild,
	ElementRef,
	ViewChildren,
	OnInit
} from '@angular/core';

import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { ResizeResult } from 'ngxtension/resize';

import _ from "lodash";

import { TacticalDashboardOverviewGaugeComponent } from '../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component';

import { Global } from '../../_constants/global.variables';

import { IGlobal } from '../../_models/global.model';

import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { UtilityService } from '../../services/utility.service';

import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import {IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';
import { TacticalWidgetSummaryTilePopupComponent } from '../../components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'lib-gs-alarm-status',
  templateUrl: './gs-alarm-status.component.html',
  styleUrls: ['./gs-alarm-status.component.scss']
})
export class GsAlarmStatusComponent implements AfterViewInit, OnInit {

  @ViewChildren('seriesItems')
  public series: any;

  public graphTypes: string[] = ['area', 'bar', 'column', 'donut', 'horizontalWaterfall', 'line', 'verticalArea', 'verticalLine', 'waterfall'];

  public graphShow: boolean = true;
	@Input() private widgetResizedEvent: EventEmitter<any>;
  @Input() private dashboardTimeScopeChanged: EventEmitter<any>;
  @ViewChild("childOverviewGauge") childOverviewGauge: TacticalDashboardOverviewGaugeComponent;
  public chartDataIsReady: boolean = false;
  public isCollapsed = false;
  public global: IGlobal = Global;

  @Input() widgetObject: any;
  h1TitlePercent: number;
  componentSubtitle: String = '';
  percentCalculated: boolean = false;
  h1Number: number = undefined;
  h4Percent: number = undefined;
  updateControllerInterval: any;
  totalAssetLength: any;
  lastUpdatedDate: Date;
  displayTimeAgoMessage: any;
  @Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
  dashboardUpdateIntervalSubscription: any;
  secondsAgoInterval: any;
  fullDataCacheSubscription: any;
  dialogModalData: any;
  chartData: any;
  noDataForSite: boolean = false;
  chartBackgroundColor: string = "#27304C"
  chartLabelColors: string = "white"
  gridLineColor: string = "white"
  axisItemColor: string = "white"
  colorChangedSubscription: any;
  theme: string;
	fontSize1 = 'medium';
	fontSize2 = 'small';
	// cardClass1 = 'card-tile-lg';
	// cardClass2 = 'card-tile-md';
	// cardWideClass2 = 'card-wide-tile-lg';
	cardTileCenterTitleClass = 'grid-card-title-center-sm';
	cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';
	headerGridTitle = 'grid-title-lg';
	contentHeight: any;
	summaryArray = [];
	summaryArray1 = [];
	summaryArray2 = [];
	sparklineData = [];
	widgetResizedSubscription: any;
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'limegreen',
	};
  barChartData: any[] = [{ Status: 10 }, { Status: 20 }, { Status: 200 }, { Status: 200 }]
  public userDefinedAlarmObservationIds: any = [56442, 56443, 56444, 56445, 56446, 56447, 56448, 56449, 56450, 56451, 56452, 56453, 56454, 56455, 56456, 56457, 56458, 56459, 56460, 56461]
  displayNoUpdateIntervalMessage: boolean;
  updateInterval: any;
  isLoading: boolean;
	widgetNavigateToFirstTabSubscription: Subscription;
	widgetToggleLegendHiddenSubscription: Subscription;

  //     AlarmName: "Alarm Cable 1"
  // AlarmTimeUTC: Tue Feb 22 2022 08:54:29 GMT-0600 (Central Standard Time) {}
  // AlarmTimeUTCMS: 1645541669830
  // AssetId: 7436
  // AssetName: "GPU"
  // GateName: "10"
  // ProperAssetName: "DAL 10 GPU"
  // SiteId: 10
  // SiteName: "DAL"

  public timeScopeObject: any;
	public tab: any;
	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: any;


  public widgetTabs: any = [
    {
      isLoading: false,
      tabHasBeenLoaded: false,
		  textLabel: "Summary",
      downloadableData: true,
		  tooltip: "Summary showing within the current scope settings (site, asset type) the GS assets in fault status.Click on the info to navigate to corresponding tab for details.",

    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
		textLabel: "Current Assets In Fault",
		graphType: this.graphTypes[3],
      legendVisible: true,
		tooltip: "Donut chart showing within the current scope settings (site, asset type) the amount of GS assets that are currently in fault status out of the total amount of assets within the selected scope and the list of names on the tooltip.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
      filterable: true,
      filters:
      {
        "filters": [
          {
            "field": "AlarmName",
            "operator": "doesnotcontain",
            "value": "No Alarm"
          }
        ],
        "logic": "and"
      }
      ,
      groupColumns: [{ field: 'ProperAssetName' }],
      tableStructure: [

        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
          _width: 300
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
          field: "AlarmName",
			title: "Fault Name",
          _width: 300
        },


      ],
    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
		textLabel: "Total Time in Fault For Day",
		graphType: this.graphTypes[5],
		tooltip: "Line chart showing total time each asset has been in fault status over the timescope period.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
      groupColumns: [{ field: 'ProperAssetName' }],
      legendVisible: true,
      tableStructure: [
        {
          field: "DateOfObservationDateObject",
          title: "Date",
          filter: 'date',
          _width: 300,
          formatOption: 'shortDate'
        },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
          _width: 300
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
          field: "AlarmCount",
			title: "Fault Count",
          _width: 300
        },
        {
          field: "AlarmDurationSeconds",
          title: "Duration in Seconds",
          _width: 300
        },


      ],
    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
		textLabel: "Detailed Fault Report",
		graphType: this.graphTypes[3],
		tooltip: "Donut chart showing total time each asset has been in fault status as a collection of time over the time scope period.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
      legendVisible: true,
      groupColumns: [{ field: 'ProperAssetName' }],
      tableStructure: [
        {
          field: "DateOfObservationDateObject",
          title: "Date",
          filter: 'date',
          _width: 300,
          formatOption: 'shortDate'
        },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
          _width: 300
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
          field: "AlarmName",
			title: "Fault Name",
          _width: 300
        },
        {
          field: "DurationInSeconds",
          title: "Duration in Seconds",
          _width: 300
        },
        {
          field: "DurationInMinutes",
          title: "Duration in Minutes",
          _width: 300
        },
        {
          field: "AlarmStartDate",
			title: "Fault Start Date",
          filter: 'date',
          _width: 300,
          // formatOption: 'shortDate'
        },
        {
          field: "AlarmEndDate",
			title: "Fault End Date",
          filter: 'date',
          _width: 300,
          // formatOption: 'shortDate'
        }


      ],
    },

  ];



  // AlarmCount:0
  // AlarmDurationSeconds:null
  // AssetId:88195
  // AssetName:'GPU'
  // DateOfObservation:'02/23/2022'
  // GateName:'A6L2'
  // SiteName:'JFK'
  gaugeDisplayData: any;
  parentContainerSize: any;
  selectedMatTabLabel: string;
  displayNoTimeScopeMessage: boolean = false;
  dashboardTimeScopeChangedSubscription: any;
  timeoutToExpire: any;
  selectedTabIndex: number = 0;
  widgetTabsChartConfigurations: any;
  widgetTabConfigurationsFinishedMapping: boolean = false;
  WidgetTabSettings: any = [];


  ConditionalShadingRecord: any;
	percentTextColor: any = [];
	conditionalColorPercentAmount: any = [];

  holeSize = 30;
  holeCenterTextClass = 'donut-center-text-md';
  donutClass = 'donut-md';
  public percentAssetsInAlarm = [];
  public percentAssetsNotInAlarm = [];


	constructor(public dataService: DataService, private utilityService: UtilityService, private dashboardService: DashboardService, private dialog: MatDialog, private elem: ElementRef, public tourService: TourService,) { }

  CreateTheme(theme: string) {
    if (theme === 'light') {
      this.theme = 'light';
      this.chartBackgroundColor = "white"
      this.chartLabelColors = "grey"
      this.gridLineColor = "grey"
      this.axisItemColor = "grey"
    } else {
      this.theme = 'dark';
      this.chartBackgroundColor = "#27304C"
      this.chartLabelColors = "white"
      this.gridLineColor = "white"
      this.axisItemColor = "white"
    }
  }

  ngOnInit() {
	this.tab = this.widgetTabs[0];
	this.updateInterval =
		this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
			this.widgetObject
		);
	this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
	this.buildNavigationOptionsArray();
}

  ngAfterViewInit() {


	var bounds = this.elem.nativeElement.getBoundingClientRect();
	this.cardResize(bounds);

	if(Global.isMobile == true) {
		this.contentHeight = 'calc(100% - 20px)';
	}
	else {
		this.contentHeight = 'calc(100% - 120px)';
	}

    let statement = "API.GetWidgetTabSettings @WidgetId = " + this.widgetObject.WidgetId;
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.WidgetTabSettings = data;
      this.WidgetTabSettings.forEach(tabSetting => {
        this.widgetTabs[tabSetting.TabIndex].legendVisible = !tabSetting.IsLegendHidden;
      })
      console.log(this);

    })

	this.ConditionalShadingRecord = this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
		return p.WidgetId == this.widgetObject.WidgetId;
	});

	if (this.ConditionalShadingRecord.length > 0) {
		this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
	}

	  //Adjust font size on Summary Tab
	  if (this.widgetResizedEvent) {
		  this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
			  if (this.widgetObject.WidgetId == data.item.WidgetId) {
				  this.cardResize(data);
				  this.fontResize(data);
			  }
		  });
	  }

	  this.widgetNavigateToFirstTabSubscription = this.dashboardService._widgetNavigateToFirstTab.subscribe((widgetObject: any) => {
		  if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
			  this.selectedTabIndex = 0
		  }
	  });

	  this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
		if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
			this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
		}
	});

    this.isLoading = true;
    if (!Global.FullDataCacheExists) {
      this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
        if (data === true) {
          this.initialize();
          this.fullDataCacheSubscription.unsubscribe();
        }
      });
    } else {

      this.initialize();
    }

    this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((data: any) => {
      if (data === "light") {
        this.CreateTheme('light');
      } else {
        this.CreateTheme('dark');
      }
    });
    if (Global.Theme === "light") {
      this.CreateTheme('light');

    } else {
      this.CreateTheme('dark');

    }

    if (this.dashboardUpdateIntervalChanged) {
      this.dashboardUpdateIntervalSubscription = this.dashboardUpdateIntervalChanged.subscribe((data) => {
        console.log(data);
        let foundWidgetWithSameWidgetId = data.find(widgetThatWasChanged => {
          return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId
        })

        if (!_.isNil(foundWidgetWithSameWidgetId)) {
          //if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
          this.initialize();
        }

      });
    }
    if (this.dashboardTimeScopeChanged) {
      this.dashboardTimeScopeChangedSubscription = this.dashboardTimeScopeChanged.subscribe((data) => {
        let foundWidgetWithSameWidgetId = data.find(widgetThatWasChanged => {
          return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId
        })

        if (!_.isNil(foundWidgetWithSameWidgetId)) {
          console.log("Widget Time Zone Changed")
          this.initialize();

        }
      });
    }
    // this.chartDataIsReady = true;

	this.tab = this.widgetTabs[0];
  }




  openConditionalShadingSettingsPopup(tileNumber) {
	let tileConditionalShadingRecord =
		this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
			return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == tileNumber;
		});
	if(tileConditionalShadingRecord.length == 0) {
		tileConditionalShadingRecord.WidgetId = this.widgetObject.WidgetId;
		tileConditionalShadingRecord.SummaryTileIndex = tileNumber;
	}

	var DialogRef = this.dialog.open(TacticalWidgetSummaryTilePopupComponent, {
		width: '50%',
		height: '40%',
		data: tileConditionalShadingRecord,
	});


	DialogRef.afterClosed().subscribe((result) => {
		let tileConditionalShadingRecordLatest =
			this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
				return p.WidgetId == this.widgetObject.WidgetId;
			});
		this.ConditionalShadingRecord = [];
		tileConditionalShadingRecordLatest.forEach((r) => {
			this.ConditionalShadingRecord.push(r);
		});

		this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
		this.ConditionalColorSummaryTiles();

	});

}

private ConditionalColorSummaryTiles() {
	this.percentTextColor[1] = this.percentTextColor[2] = "lightseagreen";

	this.conditionalColorPercentAmount[1] = this.summaryArray[2];
	this.conditionalColorPercentAmount[2] = this.summaryArray[3];


	if (this.ConditionalShadingRecord.length > 0) {
		this.ConditionalShadingRecord.forEach((r) => {

			switch (r.Operator) {
				case 'Is greater than' :
					if(this.conditionalColorPercentAmount[r.SummaryTileIndex] > r.Value) {
						this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
					}
					break;
				case 'Is less than' :
					if(this.conditionalColorPercentAmount[r.SummaryTileIndex] < r.Value) {
						this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
					}
					break;
				case 'Is equal to' :
					var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
					if(conditionalColorPercentAmountRounded == r.Value) {
						this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
					}
					break;
				case 'Is not equal to' :
					var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
					if(conditionalColorPercentAmountRounded != r.Value) {
						this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
					}
					break;
			}
		});
	}

	this.percentAssetsInAlarm = [
		{
			kind: "assets in use",
			share: this.summaryArray[2] / 100,
			color: this.percentTextColor[1],
		},
		{
			kind: "other",
			share: 1 - this.summaryArray[2] / 100,
			color: "grey",
		},
	];


	this.percentAssetsNotInAlarm = [
		{
			kind: "assets in use",
			share: this.summaryArray[3] / 100,
			color: this.percentTextColor[2],
		},
		{
			kind: "other",
			share: 1 - this.summaryArray[3] / 100,
			color: "grey",
		},
	];


}



  fontResize(data: any) {

	if (data.height < 400 || data.width < 400) {
		this.fontSize1 = 'small';
		this.fontSize2 = 'x-small';
	}

	else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
		this.fontSize1 = 'medium';
		this.fontSize2 = 'small';
	}

	else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
		this.fontSize1 = 'large';
		this.fontSize2 = 'medium';
	}
	else if (data.height > 700 || data.width > 800) {
		this.fontSize1 = 'x-large';
		this.fontSize2 = 'large';
  }
}


	cardResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			// this.cardClass2 = 'card-tile-xs';
			// this.cardWideClass2 = 'card-wide-tile-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-sm';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';

			this.holeSize = 55;
			this.holeCenterTextClass = 'donut-center-text-xs';
			this.donutClass = 'donut-sm';

		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			// this.cardClass2 = 'card-tile-sm';
			// this.cardWideClass2 = 'card-wide-tile-md';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			// this.cardClass2 = 'card-tile-sm';
			// this.cardWideClass2 = 'card-wide-tile-md';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

		}
		else if (data.height > 700 || data.width > 800) {
			// this.cardClass2 = 'card-tile-md';
			// this.cardWideClass2 = 'card-wide-tile-lg';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';
		}

		if (data.width < 400) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 400 && data.width < 600) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 600 && data.width < 800) {
			this.headerGridTitle = 'grid-title-lg';
		}
		else if (data.width > 800) {
			this.headerGridTitle = 'grid-title-lg';
		}

	}

  startIntervals() {
    this.updateInterval = this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(this.widgetObject);

    if (this.updateInterval === undefined) {
      this.isLoading = false;
      this.displayNoUpdateIntervalMessage = true;
      return;
    } else {
      this.isLoading = true;
      this.displayNoUpdateIntervalMessage = false;
    }
    let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
    if (this.secondsAgoInterval !== undefined) {
      clearInterval(this.secondsAgoInterval);
      this.secondsAgoInterval = undefined;
    }
    this.retrieveData();

    this.secondsAgoInterval = setInterval(() => {
      if (this.lastUpdatedDate !== undefined) {
        this.displayTimeAgoMessage = this.dashboardService.calculateTimeAgo(this.lastUpdatedDate);
        this.widgetObject.displayTimeAgoMessage = this.displayTimeAgoMessage;
      }
    }, 1000);
    if (this.updateControllerInterval !== undefined) {
      clearInterval(this.updateControllerInterval);
      this.updateControllerInterval = undefined;
    }
    this.updateControllerInterval = setInterval(() => {
      this.retrieveData();
    }, intervalUpdate);
  }

  destroyIntervals() {
    if (this.updateControllerInterval) {
      clearInterval(this.updateControllerInterval);
      this.updateControllerInterval = undefined;
    }
    if (this.secondsAgoInterval) {
      clearInterval(this.secondsAgoInterval);
      this.secondsAgoInterval = undefined;
    }
  }


  initialize() {
    if (_.isNil(this.widgetObject.SiteList)) {
      this.isLoading = false;
      return;
    }
    let statement = "API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = " + this.widgetObject.WidgetId + ", @userId = " + Global.User.currentUser.Id;
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.widgetTabsChartConfigurations = data;
      this.widgetTabs.forEach((tab, index) => {
        if (this.widgetTabsChartConfigurations.length > 0) {
          let foundWidgetTabConfiguration = this.widgetTabsChartConfigurations.find((configurationrecord) => {
            return configurationrecord.WidgetTabIndex === index;
          });
          if (foundWidgetTabConfiguration !== undefined) {
            tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.id == foundWidgetTabConfiguration.ChartTypeId);
          }
        }
      });
      this.widgetTabConfigurationsFinishedMapping = true;

      this.selectedTabIndex = this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0;
      this.selectedMatTabLabel = this.widgetTabs[this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0].textLabel;
      this.widgetTabs[this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0].tabHasBeenLoaded = true;
	  this.tab = this.widgetTabs[this.selectedTabIndex];

      this.startIntervals()

    })


  }

  showDialogModalPopupGrid(tab) {
    const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
		width: Global.isMobile ? '100%' : '60%',
		height: Global.isMobile ? '90%' : '80%',
		data: {
			graphId: uuidv4(),
			groupable: tab.groupable,
			filterable: tab.filterable,
			filters: tab.filters,
			groupColumns: tab.groupable === true ? tab.groupColumns : [],
			popupTitle: 'Raw Data: ' + tab.textLabel,
			WidgetName: 'Kendo-Grid',
			tableStructure: tab.tableStructure,
			tableData: tab.tableData,
			canCreateDashboardWidgetFromPopup: false,
		},
		maxWidth: '100vw',
		maxHeight: '100vh',
	});

  }


  retrieveData() {
    this.timeScopeObject = this.dashboardService.determineProperTimeScopeForWidget({
		widgetObject: this.widgetObject,
		UTCConfiguration: false,
	});
    if (this.timeScopeObject === undefined) {
      this.displayNoTimeScopeMessage = true;
      this.isLoading = false;
    } else {
      this.displayNoTimeScopeMessage = false;
      if (this.widgetTabs[0].tabHasBeenLoaded === true || this.widgetTabs[1].tabHasBeenLoaded === true) {
        this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = true;

        let statement = "API.TacticalDashboard_GS_CurrentAssetsInAlarm @accessToken= '" + Global.User.currentUser.AuthenticationTokenFromUserRecord  
		+ "'";
        if (!_.isNil(this.widgetObject.SiteList)) {
          statement = statement + ", @siteIdList = '" + this.widgetObject.SiteList + "'";
        }
        if (!_.isNil(this.widgetObject.WidgetAssetTypeId) && this.widgetObject.WidgetAssetTypeId !== 0) {
          statement = statement + ", @assetTypeId = " + this.widgetObject.WidgetAssetTypeId;
        }
        this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			if (data.length === 0) {
            this.widgetTabs[1].tableData = data;
            this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = false;
          } else {
            data.forEach(record => {
              // record.DateOfObservationDateObject = new Date(record.DateOfObservation)
              record.ProperAssetName = record.SiteName + ' ' + record.GateName + ' ' + record.AssetName;
            })

            let testData = groupBy(data, [{ field: "ProperAssetName" }]) as any[];

            console.log(testData);
            // AlarmName:'Alarm Frequency Low' || 'No Alarm'
            // AlarmTimeUTC:Tue Feb 22 2022 08:24:18 GMT-0600 (Central Standard Time)
            // AlarmTimeUTCMS:1645539858000
            // AssetId:7446
            // AssetName:'GPU'
            // GateName:'12'
            // SiteId:10
            // SiteName:'DAL'
            this.widgetTabs[1].chartData = [{
				dataType: "In Fault",
              total: 0,
              tooltip: []
            }, {
				dataType: 'No Fault',
              total: 0,
              tooltip: []
            }]

            let inAlarm = []
            let noAlarm = []

            testData.forEach(asset => {
              if (asset.items[0].AlarmName === 'No Alarm') {

                noAlarm.push(asset);



              }
              else {

                inAlarm.push(asset);
              }
            })
            inAlarm.forEach((asset, index) => {
              this.widgetTabs[1].chartData[0].total = this.widgetTabs[1].chartData[0].total + 1;
              this.widgetTabs[1].chartData[0].tooltip.push(asset.items[0].ProperAssetName)

            })



            noAlarm.forEach((asset, index) => {
              this.widgetTabs[1].chartData[1].total = this.widgetTabs[1].chartData[1].total + 1;

			  	if(this.widgetTabs[1].chartData[1].total < 6) {
					this.widgetTabs[1].chartData[1].tooltip.push(asset.items[0].ProperAssetName)
				}
				else if(this.widgetTabs[1].chartData[1].total == 6) {
					this.widgetTabs[1].chartData[1].tooltip.push("...");
				}

            })


            this.widgetTabs[0].tableData = data;
            this.widgetTabs[1].tableData = data;
            this.widgetTabs[1].chartDataIsReady = true;



            this.totalAssetLength = this.widgetTabs[1].chartData[0].total + this.widgetTabs[1].chartData[1].total;
			  // if (this.h1Number === 0) {
			  //   this.h4Percent = 0;
			  // } else {
			  //   this.h4Percent = parseFloat(((this.h1Number / this.totalAssetLength) * 100).toFixed(1));

			  // }
			  // this.gaugeDisplayData = {
			  //   h1Number: this.h1Number,
			  //   h4Percent: this.h4Percent,
			  //   totalAssetLength: this.totalAssetLength,
			  //   parentContainerSize: this.parentContainerSize
			  // }
			  this.summaryArray[0] = this.widgetTabs[1].chartData[0].total; // Assets In Alarm
			  this.summaryArray[1] = this.widgetTabs[1].chartData[1].total; // Assets With No Alarm
			  if (this.summaryArray[0] === 0) {
				  this.summaryArray[2] = 0.0 // Percent of Assets In Alarm
			  } else {
				  this.summaryArray[2] = parseFloat(((this.summaryArray[0] / this.totalAssetLength) * 100).toFixed(1)); //Percent In Comms Loss
			  }
			  if (this.summaryArray[1] === 0) {
				  this.summaryArray[3] = 0.0 // Percent of Assets Not In Alarm
			  } else {
				  this.summaryArray[3] = parseFloat(((this.summaryArray[1] / this.totalAssetLength) * 100).toFixed(1)); //Percent Not In Comms Loss
			  }


				this.ConditionalColorSummaryTiles();

            this.percentCalculated = true;
            this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = false;
          }




        })
      }
		if (
			this.widgetTabs[0].tabHasBeenLoaded === true ||
			this.widgetTabs[2].tabHasBeenLoaded === true
		) {
			this.widgetTabs[0].isLoading = this.widgetTabs[2].isLoading = true;
			let durationStatement =
				'API.TacticalDashboard_GS_Alarms_Duration ' +
				'@startDateTimeMS=' +
				this.timeScopeObject.queryStartDate +
				', @endDateTimeMS= ' +
				this.timeScopeObject.queryEndDate +
				", @accessToken='" +
				Global.User.currentUser.AuthenticationTokenFromUserRecord  
		+
				"'";
			if (!_.isNil(this.widgetObject.SiteList)) {
				durationStatement =
					durationStatement +
					", @siteIdList = '" +
					this.widgetObject.SiteList +
					"'";
			}
			if (
				!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
				this.widgetObject.WidgetAssetTypeId !== 0
			) {
				durationStatement =
					durationStatement +
					', @assetTypeId = ' +
					this.widgetObject.WidgetAssetTypeId;
			}
			this.dataService
				.SQLActionAsPromise(durationStatement)
				.then((data: any) => {
					if (data.length === 0) {
						this.widgetTabs[0].isLoading = false;
						this.widgetTabs[2].tableData = data;
						this.widgetTabs[2].chartData = data;
						this.widgetTabs[2].isLoading = false;
					} else {
						data.forEach((record) => {
							record.DateOfObservationDateObject = new Date(
								record.DateOfObservation
							);
							record.ProperAssetName =
								record.SiteName +
								' ' +
								record.GateName +
								' ' +
								record.AssetName;
							let millisecondssince1970 = new Date(
								record.DateOfObservation
							).getTime();
							record.formatMillisecondsSince1970 =
								'/Date(' + millisecondssince1970 + ')/';
							if (record.AlarmDurationSeconds === null) {
								record.AlarmDurationSeconds = 0;
							}
							record.TotalTimeInAlarmStatusMinutes = Math.round(
								record.TotalTimeInAlarmStatus / 60
							);
						});
						console.log('Duration Data');
						console.log(data);
						this.widgetTabs[2].tableData = data;
						this.widgetTabs[2].chartData = [];
						this.widgetTabs[2].chartData = groupBy(data, [
							{ field: 'ProperAssetName' },
						]) as any[];
						if (this.widgetTabs[2].chartData.length > 0) {
							this.widgetTabs[2].chartData.push({
								value: 'Toggle All Series',
							});
						}

						let sortByAlarmCount = data;
						sortByAlarmCount.sort(
							(a, b) => b.AlarmCount - a.AlarmCount
						);
						if (sortByAlarmCount.length > 0) {
							this.summaryArray1[0] =
								sortByAlarmCount[0].AlarmCount; //Highest AlarmCount
							this.summaryArray1[1] =
								sortByAlarmCount[0].DateOfObservation; //Highest AlarmCount DateOfObservation
							this.summaryArray1[2] =
								sortByAlarmCount[0].ProperAssetName; //Highest AlarmCount Asset Name
						}

						this.widgetTabs[2].chartDataIsReady = true;
						this.widgetTabs[0].isLoading =
							this.widgetTabs[2].isLoading = false;
					}
				});
		}

		if (
			this.widgetTabs[0].tabHasBeenLoaded === true ||
			this.widgetTabs[3].tabHasBeenLoaded === true
		) {
			this.widgetTabs[0].isLoading = this.widgetTabs[3].isLoading = true;
			let detailStatement =
				'API.TacticalDashboard_GS_Alarms_Detail ' +
				'@startDateTimeMS=' +
				this.timeScopeObject.queryStartDate +
				', @endDateTimeMS= ' +
				this.timeScopeObject.queryEndDate +
				" , @accessToken='" +
				Global.User.currentUser.AuthenticationTokenFromUserRecord  
		+
				"'";
			if (!_.isNil(this.widgetObject.SiteList)) {
				detailStatement =
					detailStatement +
					", @siteIdList = '" +
					this.widgetObject.SiteList +
					"'";
			}
			if (
				!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
				this.widgetObject.WidgetAssetTypeId !== 0
			) {
				detailStatement =
					detailStatement +
					', @assetTypeId = ' +
					this.widgetObject.WidgetAssetTypeId;
			}
			this.dataService
				.SQLActionAsPromise(detailStatement)
				.then((data: any) => {
					if (data.length === 0) {
						this.widgetTabs[0].isLoading = false;
						this.widgetTabs[3].tableData = data;
						this.widgetTabs[3].chartData = data;
						this.widgetTabs[3].isLoading = false;
					} else {
						data = data.filter(
							(record) => record.AlarmStartDate !== null
						);
						data.forEach((record) => {
							record.DurationInSeconds = parseInt(
								record.DurationInSeconds
							);
							record.DateOfObservationDateObject = new Date(
								record.DateOfObservation
							);
							record.ProperAssetName =
								record.SiteName +
								' ' +
								record.GateName +
								' ' +
								record.AssetName;
							record.DurationInMinutes = (
								record.DurationInSeconds / 60
							).toFixed(2);
						});

						let tempChartData = groupBy(data, [
							{ field: 'ProperAssetName' },
						]) as GroupResult[];
						tempChartData.forEach((asset: any) => {
							asset.TotalTimeInAlarmStatus = 0;
							asset.ListOfAlarmTypes = [];

							asset.items.forEach((alarm) => {
								asset.TotalTimeInAlarmStatus =
									asset.TotalTimeInAlarmStatus +
									alarm.DurationInSeconds;
								asset.ListOfAlarmTypes.push(alarm.AlarmName);
							});
							asset.ListOfAlarmTypesUnique = _.uniq(
								asset.ListOfAlarmTypes
							);
							asset.TotalTimeInAlarmStatusMinutes = Math.round(
								asset.TotalTimeInAlarmStatus / 60
							);
						});
						let sortByAlarmCountByDay = groupBy(data, [
							{ field: 'DateOfObservation' },
						]) as any[];
						sortByAlarmCountByDay.forEach((asset: any) => {
							asset.TotalTimeInAlarmStatus = 0;
							asset.TotalAlarmCount = 0;
							asset.items.forEach((alarm) => {
								asset.TotalTimeInAlarmStatus =
									asset.TotalTimeInAlarmStatus +
									parseFloat(alarm.DurationInMinutes);
								if (alarm.DurationInSeconds > 0)
									asset.TotalAlarmCount =
										asset.TotalAlarmCount + 1;
							});
						});
						sortByAlarmCountByDay.sort(
							(a, b) =>
								new Date(b.value).getTime() -
								new Date(a.value).getTime()
						);
						this.createSparkLineChart(sortByAlarmCountByDay); //Sparkline Data
						sortByAlarmCountByDay.sort(
							(a, b) => b.TotalAlarmCount - a.TotalAlarmCount
						);
						if (sortByAlarmCountByDay.length > 0) {
							this.summaryArray2[0] =
								sortByAlarmCountByDay[0].TotalAlarmCount; //Highest TotalAlarmCount
							this.summaryArray2[1] =
								sortByAlarmCountByDay[0].TotalTimeInAlarmStatus.toFixed(
									2
								); //TotalTimeInAlarmStatus on Highest TotalAlarmCount
							this.summaryArray2[2] =
								sortByAlarmCountByDay[0].value; //Date on Highest TotalAlarmCount
						}

						this.widgetTabs[3].chartData = tempChartData;

						this.widgetTabs[3].chartDataIsReady = true;
						this.widgetTabs[3].tableData = data;
						this.widgetTabs[0].isLoading =
							this.widgetTabs[3].isLoading = false;
					}
				});
		}







      this.lastUpdatedDate = new Date();

      this.isLoading = false;

	  var bounds = this.elem.nativeElement.getBoundingClientRect();
	  this.cardResize(bounds);

      // this.chartDataIsReady = true;


      // })
    }




  }

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return record.WidgetTabIndex === i;
			}
		);

		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	showChartAsPopup(tab) {
		var popupData;
		if (tab.textLabel === 'Current Assets In Fault') {
			popupData = {
				categoryField: 'dataType',
				field: 'total',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
			}
		}
		else if (tab.textLabel === 'Detailed Fault Report') {
			popupData = {
				categoryField: 'value',
				field: 'TotalTimeInAlarmStatusMinutes',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				titleXAxis: 'Minutes Assets Were In Fault For TimeScope',
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
			}
		}
		else if (tab.textLabel === 'Total Time in Fault For Day') {
			popupData = {
				categoryField: 'DateOfObservationDateObject',
				field: 'TotalTimeInAlarmStatusMinutes',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				titleXAxis: 'Total Time In Fault (Minutes)',
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				canIterate: true,
			}
		}

		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}

  private buildNavigationOptionsArray() {
	var service = this;

	var navigationWidth = Global.isMobile ? "120px" : "140px";

	service.options = [
		{
			id: 1,
			width: navigationWidth,
			name: "Navigation",
			children: [
				{
					id: 10,
					width: navigationWidth,
					name: "Summary",
					action: () => { service.navigateToTab(0, "Summary");  this.navigationOpened=false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Current In Fault",
					action: () => { service.navigateToTab(1, "Current Assets In Fault"); this.navigationOpened = false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Time In Fault / Day",
					action: () => { service.navigateToTab(2, "Total Time in Fault For Day"); this.navigationOpened = false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Overall Time in Fault",
					action: () => { service.navigateToTab(3, "Detailed Fault Report"); this.navigationOpened = false; }
				},


				// {
				// 	id: 11,
				// 	width: navigationWidth,
				// 	name: "Raw Data",
				// 	children: [
				// 		{
				// 			id: 12,
				// 			width: navigationWidth,
				// 			name: "Current In Alarm",
				// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]);  this.navigationOpened=false; }
				// 		},
				// 		{
				// 			id: 12,
				// 			width: navigationWidth,
				// 			name: "Time In Alarm / Day",
				// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]);  this.navigationOpened=false; }
				// 		},
				// 		{
				// 			id: 12,
				// 			width: navigationWidth,
				// 			name: "Overall Time in Alarm",
				// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[3]);  this.navigationOpened=false; }
				// 		},


				// 	],


				// }

			],
			root: true,
			opened: false
		},
		{
			id: 2,
			width: navigationWidth,
			name: "Settings",
			children: [
				{
					id: 10,
					width: navigationWidth,
					name: "Site",
					action: () => { service.widgetObject.editWidgetSettings(service.widgetObject);  this.navigationOpened=false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Edit Name",
					action: () => { service.widgetObject.editWidgetName(service.widgetObject);  this.navigationOpened=false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Reload",
					action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject);  this.navigationOpened=false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Delete",
					action: () => { service.widgetObject.deleteFunction();  this.navigationOpened=false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Timescope",
					action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject);  this.navigationOpened=false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Interval",
					action: () => { service.widgetObject.editIntervalSettings(service.widgetObject);  this.navigationOpened=false; }
				},
				{
					id: 10,
					width: navigationWidth,
					name: "Legend",
					action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex);  this.navigationOpened=false; }
				},

				{
					id: 23,		// id has to match id in toggle method below
					width: navigationWidth,
					name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
					action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false; }
				},

			],
			root: true,
			opened: false
		}
	];

	let optionsDesktop = [
		{
			id: 22,		// id has to match id in toggle method below
			width: navigationWidth,
			name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
			action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
		},
		{
			id: 21,		// id has to match id in toggle method below
			width: navigationWidth,
			name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
			action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
		},
	];

	if(Global.isMobile == false) {
		service.options[1].children = service.options[1].children.concat(optionsDesktop);
	}

	service.optionsSettingsOnly = service.options.where((item:any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

	console.log("options built", service.options);

}


public editWidgetSettings() {
	this.widgetObject.editWidgetSettings(this.widgetObject)
}

public refreshWidget() {
	this.dashboardService._reloadedWidget.next(this.widgetObject);
}

public changeWidgetInterval() {
	this.widgetObject.editIntervalSettings(this.widgetObject);
}

public changeWidgetTimescope() {
	this.widgetObject.editTimeScopeSettings(this.widgetObject);
}



private toggleRememberingOfSelectedTabIndexName() {
	//console.log("options:", this.options)

	var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
	var rememberTabSetting = settings.children.where((item:any) => { return item.id == 23 }).firstOrDefault();

	rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

}

private toggleDragName() {
	//console.log("options:", this.options)

	var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
	var dragSetting = settings.children.where((item:any) => { return item.id == 22 }).firstOrDefault();

	dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

}

private toggleResizeName() {
	//console.log("options:", this.options)

	var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
	var resizeSetting = settings.children.where((item:any) => { return item.id == 21 }).firstOrDefault();

	resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

}

public checkNavigation(opened: any) {
	Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
	this.navigationOpened = opened;
	this.isLoading = false;
}

public checkSelectedItem(selected: any) {
	Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
}

public openCloseNavigation() {
	Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
	this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
	Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
}


	createSparkLineChart(chartData: any) {
		this.sparklineData = [
			{
				field: 'y',
				width: 0.75,
				color: 'limegreen',
				data: chartData.map((a) => ({
					y: a.TotalAlarmCount,
					alarmMins: (a.TotalTimeInAlarmStatus).toFixed(2),
					dateOfObs: a.value,
				})),
			},
		];
	}

  toggleLegendHidden(tab, index) {
    tab.legendVisible = !tab.legendVisible;
    let foundSetting = this.WidgetTabSettings.find(widgetTabSetting => {
      return widgetTabSetting.TabIndex === index;
    })

    let statement = "API.EditWidgetTabSettings @WidgetId = " + this.widgetObject.WidgetId + ", @TabIndex = " + index + ", @IsHidden = " + !tab.legendVisible;
    if (!_.isNil(foundSetting)) {
      statement = statement + ", @Id = " + foundSetting.Id;
    }
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.WidgetTabSettings = data;
      this.WidgetTabSettings.forEach(tabSetting => {
        this.widgetTabs[tabSetting.TabIndex].legendVisible = !tabSetting.IsLegendHidden;
      })
    })

  }

  animationRedraw() {
    // if (this.selectedMatTabLabel === "Map") {
    // 	this.loadCorrectTabData(true)

    // }
  }

  navigateToTab(index, textLabel)  {
	this.tab = this.widgetTabs[index];
	  if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
		  this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);


	clearTimeout(this.timeoutToExpire);
	this.selectedMatTabLabel = textLabel;
	this.selectedTabIndex = index;
	if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
		this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
		this.destroyIntervals();
		this.startIntervals();
	}
	this.timeoutToExpire = setTimeout(() => {
		this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
		if (this.widgetObject.RememberSelectedTabIndex) {
			let returnedWidgetAsArray =
				this.dataService.updateTacticalDashboardWidgetRecord(
					this.widgetObject
				);
		}

	}, 5000);
}

  tabHasChanged(event) {

    clearTimeout(this.timeoutToExpire);
    this.selectedMatTabLabel = event.tab.textLabel;
    this.selectedTabIndex = event.index;
    if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
      this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
      this.destroyIntervals();
      this.startIntervals();
    }
    this.timeoutToExpire = setTimeout(() => {
		this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
		if (this.widgetObject.RememberSelectedTabIndex) {
			let returnedWidgetAsArray =
				this.dataService.updateTacticalDashboardWidgetRecord(
					this.widgetObject
				);
		}
	}, 5000);
  }

  onResized(event: ResizeResult): void {
    this.parentContainerSize = event.height;
    if (this.childOverviewGauge !== undefined) {
      this.childOverviewGauge.reFlow()
    }
  }

  public toggleSeries(item: any): void {

    let i = item.series.index;

    this.series._results[i].toggleVisibility();



  }

  public toggleAllSeries(e) {
    if (e.text === "Toggle All Series") {
      this.series._results.forEach((series, index) => {
        this.series._results[index].toggleVisibility();
      })
    }
  }

  initializeTour(): void {
	this.tourService.initialize([
		{
			anchorId: this.widgetObject.WidgetId + '-1',
			content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
			title: 'Settings',
		},
		{
			anchorId:  this.widgetObject.WidgetId + '-2',
			content: 'Navigate to different tabs & Widget Settings',
			title: 'Navigation',
		},
		{
			anchorId:  this.widgetObject.WidgetId + '-3',
			content: 'Click on various tiles for more information',
			title: 'Summary Info',
		},
	]);

	this.tourService.start();
}


  ngOnDestroy() {
    this.destroyIntervals();

  }

  public labelContent(e: any): string {
    return e.category + ': ' + e.value;
  }

}
